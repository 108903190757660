import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchTodo } from "../../redux/todo/slice";
import TodoInput from "./TodoInput";
import TodoList from "./TodoList";
import TodoCounter from "./TodoCounter";
import If from "../If";

const Todo = () => {
  const { state } = useSelector((state) => state.todo);

  // call api to fetch data
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTodo());
  }, [dispatch]);

  return (
    <div className="App">
      <h2>TODO</h2>
      <If condition={state === 0}>Loading ...</If>

      <If condition={state === 1}>
        <TodoCounter />
        <TodoInput />
        <TodoList />
        <button onClick={() => dispatch(fetchTodo())}>Reset</button>
      </If>

      <If condition={state === 2}>
        <div className="error">Error loading todo list</div>
        <button onClick={() => dispatch(fetchTodo())}>Retry</button>
      </If>
    </div>
  );
};

export default Todo;
