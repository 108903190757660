import { loginSuccess, loginStart, logoutSuccess } from "./slice";
import axios from "axios";

const userApi = `https://jsonplaceholder.typicode.com/users/`

// async action creator
const login = () => (dispatch) => {
  // Test API
  const userId = Math.floor(Math.random() * 10) + 1;
  const apiUrl = `${userApi}${userId}`

  dispatch(loginStart());

  axios
    .get(apiUrl)
    .then((response) => dispatch(loginSuccess(response.data.name)))
    .catch();
};

// async action creator
const logout = () => (dispatch) => {
  axios
    .get(userApi)
    .then((response) => dispatch(logoutSuccess()))
    .catch();
};

export { login, logout };
