import React from "react";
import Todo from "./components/todo";
import User from "./components/user";

const App = () => {
  return (
    <div className="App">
      <h1>React Redux with Redux-Toolkit</h1>
      <Todo />
      <User />
    </div>
  );
};

export default App;
