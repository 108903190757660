import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    name: '',
    status: 0 // 1: loading, 2: error
  },
  reducers: {
    loginStart: (state) => {
      state.status = 1
    },
    loginSuccess: (state, { payload }) => {
      state.name = payload
      state.status = 0
    },
    logoutSuccess: (state) => {
      state.name = ""
    }
  }
})

// Action creators are generated for each case reducer function
export const { loginSuccess, logoutSuccess, loginStart } = userSlice.actions

export default userSlice.reducer