import React from "react";
import { useSelector } from "react-redux";

const TodoCounter = () => {
  const { items} = useSelector((state) => state.todo);
  const doneCount = items.filter((todo) => todo.complete).length;
  const todoCount = items.length - doneCount;

  return (
    <div className="counter">
      <span>Done: {doneCount}</span>
      <span>TODO: {todoCount}</span>
    </div>
  );
};

export default TodoCounter;
