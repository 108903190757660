import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeTodo, toggleTodo } from "../../redux/todo/slice";

const TodoList = () => {
  const dispatch = useDispatch();
  const { items } = useSelector((state) => state.todo);

  const handleOnClick = (id) => {
    dispatch(removeTodo(id));
  };

  const handleOnChange = (id) => {
    dispatch(toggleTodo(id));
  };
  return (
    <ul>
      {items.map((todo) => (
        <li key={todo.id} className={todo.complete ? "complete" : "todo"}>
          <input
            type="checkbox"
            onChange={handleOnChange.bind(null, todo.id)}
            id={todo.id}
          />
          <label htmlFor={todo.id}>{todo.name}</label>
          <button onClick={handleOnClick.bind(null, todo.id)}>X</button>
        </li>
      ))}
    </ul>
  );
};

export default TodoList;
