import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import logger from './middleware'

import todoReducer from './todo/slice'
import userReducer from './user/slice'

export default configureStore({
  reducer: combineReducers({
    todo: todoReducer,
    user: userReducer,
  }),
  middleware: [...getDefaultMiddleware(), logger]
})