import uid from "uid";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../constants";
import callApi from "../../services/apiService";

export const fetchTodo = createAsyncThunk('fetchTodo', async () => {
  const response = await callApi(API_URL)
  return response.data
})

export const todoSlice = createSlice({
  name: "todo",
  initialState: {
    items: [],
    state: 0, // 0:Loading, 1:Success, 2:Error
  },
  reducers: {
    addTodo: (state, { payload }) => {
      const todoItem = {
        id: uid(),
        name: payload,
        complete: false,
      };
      state.items.unshift(todoItem);
    },
    removeTodo: (state, { payload }) => {
      state.items = state.items.filter((todo) => todo.id !== payload);
    },
    toggleTodo: (state, { payload }) => {
      const selectedItem = state.items.find((i) => i.id === payload);
      selectedItem.complete = !selectedItem.complete;
    },
  },
  extraReducers: {
    [fetchTodo.pending]: (state) => {
      state.state = 0
    },
    [fetchTodo.fulfilled]: (state, { payload }) => {
      state.state = 1
      state.items = payload
    },
    [fetchTodo.rejected]: (state, { error }) => {
      state.state = 2

      // additional error handling if required
      // console.error(error)
    }
  }
});


// Action creators are generated for each case reducer function
export const {
  fetchTodoStart,
  fetchTodoSuccess,
  addTodo,
  removeTodo,
  toggleTodo,
  fetchTodoError,
} = todoSlice.actions;

export default todoSlice.reducer;
