import axios from "axios";

//// use create and interceptors to set common properties for API requests
const callApi = axios.create({
  baseURL: "/",
  headers: {
    "Content-Type": "application/json",
  },
});

callApi.interceptors.request.use(
  (config) => {
    // set additional data to header request if needed
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default callApi;
