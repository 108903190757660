import React from "react";
import { login, logout } from "../../redux/user/saga";
import { useDispatch, useSelector } from "react-redux";

import If from "../If";

const User = () => {
  const { name } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const dispatchLogin = () => {
    dispatch(login("Wilson"));
  };

  const dispatchLogout = () => {
    dispatch(logout());
  };

  return (
    <div id="user-panel">
      <If condition={!!name}>
        <div id="user-name">{name}</div>
        <button onClick={dispatchLogout}>Logout</button>
      </If>
      <If condition={!name}>
        <button onClick={dispatchLogin}>Login</button>
      </If>
    </div>
  );
};

export default User;
