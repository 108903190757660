import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addTodo } from "../../redux/todo/slice";

const TodoInput = () => {
  const [todo, setTodo] = useState("");
  const dispatch = useDispatch();

  const handleOnChange = (e) => {
    setTodo(e.target.value);
  };

  const handleOnClick = (e) => {
    e.preventDefault();
    setTodo("");

    todo && dispatch(addTodo(todo));
  };

  return (
    <div>
      <input type="text" value={todo} onChange={handleOnChange} />
      <button onClick={handleOnClick}>Add</button>
    </div>
  );
};

export default TodoInput;
